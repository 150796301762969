import styled from 'styled-components'

// export const PageContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   background: #fff;
//   padding: 112px 0;
//   @media (max-width: 768px) {
//     padding: 39px 0 48px 0;
//   }
// `;
export const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 48px;
  margin: 0 auto;

  li{
    position: relative;
  }
  
  h3 {
    color: #fff;
    line-height: 52px;
  }

  p {
    color: #fff;
    line-height: 28px;
    padding-bottom: 24px;
  }

  //   margin: 0 0 80px 0;
  //   text-align: left;
  //   font-size: 22px;
  //   color: white;
  //   width: 100%;
  //   @media (max-width: 768px) {
  //     font-size: 22px;
  //     line-height: 30px;
  //   }
  //   @media (max-width: 500px) {
  //     width: 80%;
  //   }
  // }
  // p {
  //   text-align: center;
  //   margin: 0 0 8px 0;
  //   @media (max-width: 768px) {
  //     width: 70%;
  //   }
  // }

  .carousel-caption{
    position: relative;
    background-color: transparent;

  }
  .black-box{
    max-width: 450px;
    min-height: 180px;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #333;
    padding: 15px 20px;
    opacity: 0.9;
    -moz-border-radius: 1em;
    -webkit-border-radius: 1em;
    z-index: 0;
    @media(max-width: 1000px){
      max-width: 100%;
      margin: 0 10px;
    }
  }
  
  .splide{
    width: 100%;
  }

  .splide__arrows{
    @media(max-width: 1000px){
      display: none;
    }
  }


  .splide.is-initialized {
    // width: 100%;
    // min-width: 100%;
    padding: 0px;
    margin: 0px;
    // height: 500px;
    // @media(max-width: 1000px){
    //   padding: 0 20px;
    // }
    // @media(max-width: 500px){
    //   padding: 0 40px;
    // }
  }
  .splide__pagination {
    transform: translateY(80px);
    &__page {
      background: #C4C4C4;
      &.is-active{
        background: rgba(0, 17, 51, 1);
      }
    }
  }
  .splide__track{
    height: 500px;

  }
  .splide__slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 18px;
    line-height: 24px;
    cursor: grab;
    &:last-child{
      .slide-top{
        img {
          margin-left: -25px;
        }
      }
    }
  }
  .slide-top {
    position: absolute;
    top: 0;
    width: 100%;
    height: 160px;
    min-height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#FAFAFB;

      img {
        width: 100%;
        // max-width: 200px;
      }
  }
  .slide-bot{
    position: absolute;
    top: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 32px 24px 32px;
    h3 {
      font-style: italic;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(0, 17, 51, 0.8);
    }
  }
`;