import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Image from '../components/Image';

import styled from "styled-components";

const UL = styled.ul`
  font-size : 16px;
`;

const LI = styled.li`
  font-size : 16px;
`;

const Header = ({ siteTitle }) => (
  <div className="navbar-wrapper">
      
      <div className="container">

        <div className="navbar navbar-inverse">
          <div className="navbar-inner">
            
            <button type="button" className="btn btn-navbar" data-toggle="collapse" data-target=".nav-collapse">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            
            <Link className="brand"  to="/" style={{top:'10px'}}>
            <Image srcName={'Logo.png'} alt={'Esov soluciones integrales'}/>
              </Link>
            
            <div className="nav-collapse collapse">
              <UL className="nav" style={{margin:0}}>
                <LI><Link to="/empresa">Empresa</Link></LI>
                <LI><Link to="/servicios">Obras Civiles</Link></LI>
                <LI><Link to="/aseoindustrial">Aseo Industrial</Link></LI>
                <LI><Link to="/vidrios-aluminios">Vidrios y Aluminios</Link></LI>
                <LI><Link to="/laminas-de-seguridad">Láminas de Seguridad</Link></LI>
                <LI><Link to="/clientes">Clientes</Link></LI>
                <LI><Link to="/contacto">Contacto</Link></LI>
              </UL>
            </div>
          </div>
        </div>

      </div> 
    </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
