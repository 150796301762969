import React from "react";
import styled from "styled-components";

const Flag = styled.img`
`;

const Image = ({ srcName, alt }) => {
  const flag = require(`../images/${srcName}`).default;
  return (
    <>
      <Flag className="flag" src={flag} alt={alt} />
    </>
  );
};

export default Image;
