import React from "react";
import styled from "styled-components";

const Flag = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  // min-width: 100%;
  width:100%;
  height: 500px;
`;

const FlagResolver = ({ srcName, alt }) => {
  const flag = require(`../../images/${srcName}.png`).default;
  return (
    <>
      <Flag className="flag" src={flag} alt={alt} />
    </>
  );
};

export default FlagResolver;
