
import React from 'react';
import { Link } from "gatsby"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import '@splidejs/splide/dist/css/splide.min.css';

import { SectionContainer } from "./SliderSliceStyles";
import Flag from './FlagResolver';


const slices = [
    { src : "slide-02", alt:'ESOV', title:'Nos caracterizamos', text:'Por una fuerte vocación de servicio y orientación al cliente, entregamos flexibilidad a sus necesidades.', textAction:'Cotice' ,action:'' },
    { src : "slide-02", alt:'ESOV', title:'Entregamos Soluciones', text:'Desarrollado sus actividades en óptimas condiciones, desde el diseño, fabricación y mantención', textAction:'Cotice' ,action:'' },
    { src : "slide-07", alt:'ESOV', title:'Láminas de Seguridad', text:'', textAction:'Cotice' ,action:'' },
    { src : "slide-06", alt:'ESOV', title:'Soluciones Integrales', text:'', textAction:'Cotice' ,action:'' },
    { src : "slide-03", alt:'ESOV', title:'Garantizar un optimo resultado', text:'A todas sus necesidades ya que contamos con un equipo profesional con la capacitación necesaria.', textAction:'Cotice' ,action:'' },
    { src : "slide-04", alt:'ESOV', title:'Somos Especialistas', text:'', textAction:'Cotice' ,action:'En Construcción, Reparación y Montaje de cortinas metálicas, pintura, gasfitería, electricidad entre otras' },
]
export const Slider = ()=>{


    return(
        <SectionContainer>
            <Splide
                options={ {
                    rewindSpeed: 1000,
                    type         : 'loop',
                    gap          : '1rem',
                    autoplay     : true,
                    pauseOnHover : false,
                    resetProgress: false,
                    arrows       : 'slider',
                    height       : '15rem',
                } }
                style={{ backgroundColor: 'transparent' }}

                // hasSliderWrapper
                // hasAutoplayControls
                // hasAutoplayProgress
            >
                { slices.map( (slide, index) => (
                <SplideSlide key={ `${index}-${slide.src}` }>
                    <Flag srcName={slide.src} alt={slide.alt}/>
                   
                    <div className="container">
                        <div className="carousel-caption">
                            <div className='black-box'>
                                <h3>{ slide.title }</h3>
                                <p>{ slide.text }</p>
                                <Link to="/contacto" className="btn btn-large btn-primary">{ slide.textAction }</Link>
                            </div>
                        </div>
                    </div>
                </SplideSlide>
                ) ) }
            </Splide>
        </SectionContainer>
    )
}