/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {Slider} from './Slider';

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <Slider />
      <div>
          <div className="container" style={{padding:0}}>
            {children}
          </div>
        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          <hr />
          <p className="pull-right"></p>
          <p style={{textAlign:'center'}}>
            <span className="apgca">
              <a href="http://www.apgca.cl">Copyright © 2013 | GCA Desarrollo Web</a> <br />
            </span>
              <span>AUTOPISTA CONCEPCION -TALCAHUANO N 6041 CONCEPCIÓN</span><br />
              <span>TELÉFONOS: (+569)94202463	|	(+569)97142603  . </span>
            (041)- 2 169115
          </p>
          {/* © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a> */}
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
